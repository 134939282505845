<template>
  <div id="presentation">
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <main class="main-presentation">
      <div class="div-presentation-img">
        <img
          src="./../images/logo-seall-white.png"
          alt="Logo SEALL"
          class="img-logo"
        />
        <button class="div-presentation-button-header">
          {{ $t("presentation.button_top") }}
        </button>
      </div>

      <div class="div-presentation-texts">
        <h2>{{ $t("presentation.title") }}</h2>
        <p>{{ $t("presentation.subtile") }}</p>
      </div>

      <div class="div-presentation-cards">
        <carousel :items-to-show="valor" style="width: 100%">
          <slide key="slide">
            <router-link
              to="/questions"
              class="links-header"
              style="text-decoration: none"
            >
              <div class="div-presentation-card">
                <div class="div-presentation-card-img">
                  <img src="./../images/card-icon1.svg" alt />
                  <svg width="60" height="60" class="div-presentation-card-svg">
                    <circle
                      stroke="#E0E0E0"
                      stroke-width="2"
                      cx="30"
                      cy="30"
                      r="28"
                      fill="#FFFFFF"
                    />
                    <text
                      fill="#00B7BB"
                      font-size="30"
                      font-family="NunitoSans-Regular"
                      x="50%"
                      y="55%"
                      dominant-baseline="middle"
                      text-anchor="middle"
                    >
                      1
                    </text>
                  </svg>
                </div>
                <h3 style="color: #00b7bb">
                  {{ $t("presentation.cards.card1.title") }}
                </h3>
                <p class="description">
                  {{ $t("presentation.cards.card1.description") }}
                </p>
                <span style="color: #00b7bb">
                  {{ $t("presentation.cards.card1.type") }}
                </span>
              </div>
            </router-link>
          </slide>
          <slide key="slide">
            <div class="div-presentation-card">
              <div class="div-presentation-card-img">
                <img src="./../images/card-icon2.svg" alt />
                <svg width="60" height="60" class="div-presentation-card-svg">
                  <circle
                    stroke="#E0E0E0"
                    stroke-width="2"
                    cx="30"
                    cy="30"
                    r="28"
                    fill="#FFFFFF"
                  />
                  <text
                    fill="#ED9F40"
                    font-size="30"
                    font-family="NunitoSans-Regular"
                    x="50%"
                    y="55%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                  >
                    2
                  </text>
                </svg>
              </div>
              <h3 style="color: #ed9f40">
                {{ $t("presentation.cards.card2.title") }}
              </h3>
              <p class="description">
                {{ $t("presentation.cards.card2.description") }}
              </p>
              <div class="div-presentation-card-lock">
                <span style="color: #ed9f40">
                  {{ $t("presentation.cards.card2.type") }}
                </span>
                <i class="material-icons" style="color: #ed9f40">lock</i>
              </div>
            </div>
          </slide>
          <slide key="slide">
            <div class="div-presentation-card">
              <div class="div-presentation-card-img">
                <img src="./../images/card-icon3.svg" alt />
                <svg width="60" height="60" class="div-presentation-card-svg">
                  <circle
                    stroke="#E0E0E0"
                    stroke-width="2"
                    cx="30"
                    cy="30"
                    r="28"
                    fill="#FFFFFF"
                  />
                  <text
                    fill="#ED9F40"
                    font-size="30"
                    font-family="NunitoSans-Regular"
                    x="50%"
                    y="55%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                  >
                    3
                  </text>
                </svg>
              </div>
              <h3 style="color: #ed9f40">
                {{ $t("presentation.cards.card3.title") }}
              </h3>
              <p class="description">
                {{ $t("presentation.cards.card3.description") }}
              </p>
              <div class="div-presentation-card-lock">
                <span style="color: #ed9f40">
                  {{ $t("presentation.cards.card3.type") }}
                </span>
                <i class="material-icons" style="color: #ed9f40">lock</i>
              </div>
            </div>
          </slide>
          <slide key="slide">
            <div class="div-presentation-card">
              <div class="div-presentation-card-img">
                <img src="./../images/card-icon4.svg" alt />
                <svg width="60" height="60" class="div-presentation-card-svg">
                  <circle
                    stroke="#E0E0E0"
                    stroke-width="2"
                    cx="30"
                    cy="30"
                    r="28"
                    fill="#FFFFFF"
                  />
                  <text
                    fill="#533A8B"
                    font-size="30"
                    font-family="NunitoSans-Regular"
                    x="50%"
                    y="55%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                  >
                    4
                  </text>
                </svg>
              </div>
              <h3 style="color: #533a8b">
                {{ $t("presentation.cards.card4.title") }}
              </h3>
              <p class="description">
                {{ $t("presentation.cards.card4.description") }}
              </p>
              <div class="div-presentation-card-lock">
                <span style="color: #533a8b">
                  {{ $t("presentation.cards.card4.type") }}
                </span>
                <i class="material-icons" style="color: #533a8b">lock</i>
              </div>
            </div>
          </slide>
          <slide key="slide">
            <div class="div-presentation-card">
              <div class="div-presentation-card-img">
                <img src="./../images/card-icon5.svg" alt />
                <svg width="60" height="60" class="div-presentation-card-svg">
                  <circle
                    stroke="#E0E0E0"
                    stroke-width="2"
                    cx="30"
                    cy="30"
                    r="28"
                    fill="#FFFFFF"
                  />
                  <text
                    fill="#533A8B"
                    font-size="30"
                    font-family="NunitoSans-Regular"
                    x="50%"
                    y="55%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                  >
                    5
                  </text>
                </svg>
              </div>
              <h3 style="color: #533a8b">
                {{ $t("presentation.cards.card5.title") }}
              </h3>
              <p class="description">
                {{ $t("presentation.cards.card5.description") }}
              </p>
              <div class="div-presentation-card-lock">
                <span style="color: #533a8b">
                  {{ $t("presentation.cards.card5.type") }}
                </span>
                <i class="material-icons" style="color: #533a8b">lock</i>
              </div>
            </div>
          </slide>
          <slide key="slide">
            <div class="div-presentation-card">
              <div class="div-presentation-card-img">
                <img src="./../images/card-icon6.svg" alt />
                <svg width="60" height="60" class="div-presentation-card-svg">
                  <circle
                    stroke="#E0E0E0"
                    stroke-width="2"
                    cx="30"
                    cy="30"
                    r="28"
                    fill="#FFFFFF"
                  />
                  <text
                    fill="#533A8B"
                    font-size="30"
                    font-family="NunitoSans-Regular"
                    x="50%"
                    y="55%"
                    dominant-baseline="middle"
                    text-anchor="middle"
                  >
                    6
                  </text>
                </svg>
              </div>
              <h3 style="color: #533a8b">
                {{ $t("presentation.cards.card6.title") }}
              </h3>
              <p class="description">
                {{ $t("presentation.cards.card6.description") }}
              </p>
              <div class="div-presentation-card-lock">
                <span style="color: #533a8b">
                  {{ $t("presentation.cards.card6.type") }}
                </span>
                <i class="material-icons" style="color: #533a8b">lock</i>
              </div>
            </div>
          </slide>
        </carousel>
      </div>

      <div class="div-presentation-links">
        <router-link
          to="/login"
          class="links-header"
          style="text-decoration: none"
        >
          <div class="div-presentation-buttons">
            <button class="div-presentation-button-text">
              {{ $t("presentation.button_bottom") }}
            </button>
            <button class="div-presentation-button-icon">&#10132;</button>
          </div>
        </router-link>
      </div>
    </main>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "presentation",
  data() {
    return {
      valor: 4.5,
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (window.screen.availWidth < 1025) this.valor = 1.25;
    else this.valor = 4.5;
  },
  methods: {},
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style scoped>
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Lato-Regular;
  src: url(../fonts/Lato/Lato-Regular.ttf);
}

html,
body {
  border: 0vh 0vw;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  border: 0vh 0vw;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  height: 100%;
  width: 100%;
}

.main-presentation {
  background-image: url("./../images/background-samepeople.png");
  background-repeat: repeat;
  background-size: cover;
  min-height: 100vh;
}

.div-presentation-img {
  padding: 2vh 5vw;
  display: flex;
  justify-content: space-between;
}

.div-presentation-img img {
  width: 10vw;
}

.div-presentation-button-header {
  border-style: solid;
  border-radius: 8px;
  color: #ffffff;
  border-color: #ffffff;
  background: transparent;
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  height: 5vh;
  padding: 3vh 2vw;
  display: flex;
  align-items: center;
}

.div-presentation-texts {
  padding: 2vh 5vw;
  width: 35vw;
}

.div-presentation-texts h2 {
  color: #ffffff;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 133%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.div-presentation-texts p {
  color: #ffffff;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 125%;
  margin: 0vh 0vw;
  padding: 2vh 0vw;
}

.div-presentation-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0vh 0vw 0vh 5vw;
}

.div-presentation-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0vh 0.5vw;
  padding: 2vh 2vw;
  height: 50vh;
}

.div-presentation-card-img {
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0vh 0vw;
  padding: 0vh 0vw 2vh;
}

.div-presentation-card img {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
}

.div-presentation-card h3 {
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 3vh;
  line-height: 115%;
  margin: 0vh 0vw;
  padding: 0vh 0vw 0vh;
  text-align: left;
  align-items: center;
  display: flex;
  height: 15%;
}

.div-presentation-card p {
  color: #464b52;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 2vh;
  line-height: 150%;
  margin: 0vh 0vw;
  padding: 2vh 0vw;
  text-align: left;
  height: 60%;
}

.div-presentation-card-lock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.div-presentation-card span {
  display: flex;
  flex-direction: row;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 2vh;
  line-height: 150%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  text-align: left;
}

.div-presentation-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 5vh 10vw;
}

.div-presentation-buttons {
  background: #ffffff;
  box-shadow: 0px 3px 5px -1px rgba(112, 119, 128, 0.2),
    0px 6px 10px rgba(112, 119, 128, 0.14),
    0px 1px 18px rgba(112, 119, 128, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.div-presentation-button-text {
  border-style: hidden;
  color: #533a8b;
  font-family: Lato-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  height: 5vh;
  padding: 3vh 2vw;
  display: flex;
  align-items: center;
}

.div-presentation-button-icon {
  border-style: hidden;
  background: #00b7bb;
  color: #ffffff;
  font-family: Lato-Regular;
  font-style: bold;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  height: 5vh;
  padding: 3vh 1.5vw;
  display: flex;
  align-items: center;
}

p.description {
  max-height: 310px;
  overflow-y: auto;
}

@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 10%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .div-presentation-img img {
    width: 30vw;
  }

  .div-presentation-texts {
    margin: 0vh 5vw;
    padding: 0vh 0vw;
    width: 90vw;
  }

  .div-presentation-card {
    margin: 0vh 1.5vw;
    padding: 2vh 5vw;
  }
}
</style>